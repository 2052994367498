import React from "react"

import { Outlet } from "react-router-dom"

import Layout from "layout/Layout"

const Root = () => (
  <Layout>
    <Outlet />
  </Layout>
)

export default Root
