import React from "react"

import { type OktaAuth, toRelativeUrl } from "@okta/okta-auth-js"
import { Security } from "@okta/okta-react"
import { Outlet, useNavigate } from "react-router-dom"

import { oktaAuth } from "utils/authentication.utils"

const SecurityContainer = () => {
  const navigate = useNavigate()

  const restoreOriginalUri = async (_: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    })
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Outlet />
    </Security>
  )
}

export default SecurityContainer
