import axios from "axios"

import * as ENV from "config/env"
import type { InvoicesSettings, OrdersGET, ProductsGET, ProductStatisticsGET, SpotlightsGET, StatisticsGET } from "types/api.types"
import type { OrderDetail, Product, Spotlight } from "types/common.types"
import { SECTIONS } from "types/enums.types"
import { ProductForm, SpotlightForm } from "types/form.types"
import { LOCAL_STORAGE } from "utils/authentication-constants.utils"
import { oktaAuth } from "utils/authentication.utils"
import { formatDate } from "utils/format.utils"

import endpoints from "./endpoints"

axios.defaults.baseURL = ENV.API_BASE_URL
// 5 minutes
axios.defaults.timeout = 60000 * 5
/*const paramsSerializer = (params: unknown) => {
    return qs.stringify(params, { arrayFormat: "repeat" })
}*/

const ws = axios.create({ maxRedirects: 0 })

const replacer = function (key: string, value: unknown) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (this[key] instanceof Date) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return formatDate(this[key])
  }
  return value
}

const api = {
  spotlights: {
    create: (body: SpotlightForm, image?: File): Promise<{ data: Spotlight }> => {
      const form = new FormData()
      const { id, ...rest } = body
      const json = JSON.stringify(rest, replacer)
      const blob = new Blob([json], {
        type: "application/json",
      })
      form.append("body", blob)
      form.append("image", image as Blob)

      if (id) {
        return ws.post(endpoints.spotlights.update(id.toString()), form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      } else {
        return ws.put(endpoints.spotlights.put, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      }
    },
    get: (): Promise<{ data: SpotlightsGET }> => ws.get(endpoints.spotlights.get),
    order: (id: number, newOrder: number): Promise<{ data: SpotlightsGET }> =>
      ws.get(endpoints.spotlights.order, { params: { id, newOrder } }),
    publish: (id: string): Promise<{ data: SpotlightsGET }> => ws.get(endpoints.spotlights.publish(id)),
    delete: (id: string): Promise<boolean> => ws.delete(endpoints.spotlights.delete(id)),
  },
  products: {
    get: (section?: SECTIONS): Promise<{ data: ProductsGET }> => ws.get(endpoints.products.get, { params: { section } }),
    order: (id: number, newOrder: number): Promise<{ data: SpotlightsGET }> =>
      ws.get(endpoints.products.order, { params: { id, newOrder } }),
    delete: (id: string): Promise<boolean> => ws.delete(endpoints.products.delete(id)),
    create: (body: ProductForm, image?: File): Promise<{ data: Product }> => {
      const form = new FormData()
      const { id, ...rest } = body
      const json = JSON.stringify(rest, replacer)
      const blob = new Blob([json], {
        type: "application/json",
      })
      form.append("body", blob)
      form.append("image", image as Blob)

      if (id) {
        return ws.post(endpoints.products.update(id.toString()), form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      } else {
        return ws.put(endpoints.products.put, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      }
    },
  },
  statistics: {
    get: (params?: { section?: string; dateMin?: string; dateMax?: string }): Promise<{ data: StatisticsGET }> =>
      ws.get(endpoints.statistics.get, { params }),
    getByReference: (params?: { q?: string; dateMin?: string; dateMax?: string }): Promise<{ data: ProductStatisticsGET[] }> =>
      ws.get(endpoints.statistics.getProducts, { params }),
  },
  invoices: {
    settings: (): Promise<{ data: InvoicesSettings }> => ws.get(endpoints.invoices.settings),
    update: (form: string[]): Promise<{ data: InvoicesSettings }> => ws.post(endpoints.invoices.settings, form),
  },
  orders: {
    getOrders: (params?: { page?: number; size?: number; q?: string }): Promise<{ data: OrdersGET }> =>
      ws.get(endpoints.orders.getOrders, { params }),
    getOrder: (id: number): Promise<{ data: OrderDetail }> => ws.get(endpoints.orders.getOrder(id)),
  },
}

ws.interceptors.request.use(async (request) => {
  request.headers = request.headers || {}
  request.headers["x-authenticate-mode"] = "OKTA"
  request.headers["Authorization"] = `Bearer ${oktaAuth.getIdToken()}`
  request.headers["x-agent"] = "WEB"
  return request
})

/* FORCE IDP TOKEN (DEV/DEBUG USAGE ONLY) */
/*
ws.interceptors.request.use(async (request: any) => {
  ws.defaults.headers.common = {
    Authorization: ENV.DEV_TOKEN,
    "x-authenticate-mode": "IDP"
  };

  if (request.headers) {
    request.headers.Authorization = ENV.DEV_TOKEN;
    request.headers["x-authenticate-mode"] = "IDP";
  }

  return request;
});
*/

ws.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (axios.isCancel(error)) {
      return
    }

    if (
      error.response &&
      ((error.response.data && error.response.data.status && error.response.data.status === 401) ||
        (error.response.status && error.response.status === 401))
    ) {
      /* If not logged, save current uri and reset apiToken */
      // localStorage.setItem(LOCAL_STORAGE.REDIRECT_URL, window.location.pathname);
      localStorage.removeItem(LOCAL_STORAGE.API_TOKEN)

      window.dispatchEvent(new CustomEvent("resetAuth"))
    }

    throw error
  }
)

export default api
