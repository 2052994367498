import type { IntlShape } from "react-intl"

import Home from "screens/home"
import Order from "screens/order"
import Orders from "screens/orders"
import ProductCreate from "screens/productCreate"
import Products from "screens/products"
import SpotlightCreate from "screens/spotlightCreate"
import Spotlights from "screens/spotlights"

const route = (id: string, intl: IntlShape) => intl.formatMessage({ id })

export const ROUTES_CONFIG = (intl: IntlShape) => {
  return [
    {
      path: "/",
      Component: Home,
    },
    {
      path: route("route.orders", intl),
      Component: Orders,
    },
    {
      path: route("route.orders.details", intl),
      Component: Order,
    },
    {
      path: route("route.spotlights", intl),
      Component: Spotlights,
    },
    {
      path: route("route.spotlight.create", intl),
      Component: SpotlightCreate,
    },
    {
      path: route("route.products", intl),
      Component: Products,
    },
    {
      path: route("route.product.create", intl),
      Component: ProductCreate,
    },
  ]
}
