import React from "react"

import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, Tooltip, Typography } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"

import api from "api/api"
import { COLORS } from "assets/styles/colors"
import { useSnackbarPromiseHandler } from "hooks/useSnackbar"
import { useReduxDispatch } from "store"
import actions from "store/actions"
import { Spotlight as TypeSpotlight } from "types/common.types"
import { formatDateDisplay, formatNull } from "utils/format.utils"

import * as S from "./Spotlight.styles"

type Props = {
  spotlight: TypeSpotlight
  onClick?: () => unknown
}

const Spotlight = ({ spotlight, onClick }: Props) => {
  const { link, creationDate, modificationDate, adjustment, title, subtitle, startDate, endDate, image, published, id } = spotlight || {}
  const intl = useIntl()
  const dispatch = useReduxDispatch()
  const [, clickVisibility] = useSnackbarPromiseHandler(async () => {
    await api.spotlights.publish(id.toString())
    dispatch(actions.spotlights.refresh())
  })

  const sDate = startDate && new Date() < new Date(startDate)
  const eDate = endDate && new Date() > new Date(endDate)

  return (
    <S.Card onClick={onClick}>
      {published && (sDate || eDate) && (
        <Box p={1} sx={{ width: "100%", position: "absolute", backgroundColor: `${COLORS.PRIMARY_LIGHT}E6`, color: "black" }}>
          {sDate && (
            <Typography variant="body1">
              <FormattedMessage id="spotlight.startDate.error" />{" "}
            </Typography>
          )}
          {eDate && (
            <Typography variant="body1">
              <FormattedMessage id="spotlight.endDate.error" />{" "}
            </Typography>
          )}
        </Box>
      )}
      <S.Overlay className="overlay">
        <Box display="flex" p={2} flexDirection="column">
          <Box>
            <Typography variant="h6" sx={{ pr: 1 }}>
              <FormattedMessage id="spotlight.title" />
            </Typography>
            {title}
          </Box>
          <Box>
            <Typography variant="h6" sx={{ pr: 1 }}>
              <FormattedMessage id="spotlight.link" />
            </Typography>
            {formatNull(link)}
          </Box>
          <Box>
            <Typography variant="h6" sx={{ pr: 1 }}>
              <FormattedMessage id="spotlight.creationDate" />
            </Typography>
            {formatDateDisplay(intl, creationDate)}
          </Box>
          <Box>
            <Typography variant="h6" sx={{ pr: 1 }}>
              <FormattedMessage id="spotlight.modificationDate" />
            </Typography>
            {formatDateDisplay(intl, modificationDate)}
          </Box>
        </Box>
      </S.Overlay>
      <S.Container>
        <S.IconContainer
          onClick={(e) => {
            e.stopPropagation()
            clickVisibility()
          }}
        >
          <Tooltip arrow title={intl.formatMessage({ id: `screen.product.visibility.${published ? "on" : "off"}` })}>
            {published ? <VisibilityOff /> : <Visibility />}
          </Tooltip>
        </S.IconContainer>
        <S.ImageContainer>{image && <S.Image src={image} adjust={adjustment} image={!!image} />}</S.ImageContainer>
        <Box p={2} pb={1} pt={1}>
          <S.Title>{title}</S.Title>
          <S.SubTitle>{subtitle}</S.SubTitle>
          <S.DateContainer>{formatDateDisplay(intl, startDate)}</S.DateContainer>
        </Box>
      </S.Container>
    </S.Card>
  )
}

export default Spotlight
