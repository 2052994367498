// PKCE HELPER FUNCTIONS
import DefaultOktaAuth, { type AuthState, type OktaAuthOptions } from "@okta/okta-auth-js"

import { OKTA_CONFIG } from "config/env"
import { Credentials } from "store/auth/auth.types"

const config: OktaAuthOptions = {
  issuer: OKTA_CONFIG.ISSUER,
  redirectUri: OKTA_CONFIG.REDIRECT_URI,
  logoutUrl: OKTA_CONFIG.LOGOUT_URL,
  clientId: OKTA_CONFIG.CLIENT_ID,
  scopes: OKTA_CONFIG.REQUESTED_SCOPES,
}

export const oktaAuth = new DefaultOktaAuth(config)

export const getCredentials = (authState: AuthState): Credentials | undefined => {
  const { accessToken, idToken } = authState

  if (!accessToken || !idToken) {
    return undefined
  }

  return {
    idToken: idToken.idToken,
    accessToken: accessToken.accessToken,
  }
}
