import React from "react"

import { LoginCallback } from "@okta/okta-react"
import { useIntl } from "react-intl"
import { type RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom"

import AbsoluteLoader from "components/loader"
import * as ENV from "config/env"
import Root from "routers/Root"
import SecurityContainer from "routers/SecurityContainer"
import { ROUTES_CONFIG } from "utils/navigation.utils"

import SecureRoute from "./routers/SecureRoute"

function App() {
  const intl = useIntl()

  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <SecurityContainer />,
        children: [
          {
            path: "/login",
            element: <LoginCallback loadingElement={<AbsoluteLoader />} />,
          },
          {
            path: "/",
            element: <SecureRoute />,
            children: [
              {
                path: "/",
                element: <Root />,
                children: ROUTES_CONFIG(intl) as RouteObject[],
              },
            ],
          },
        ],
      },
    ],
    { basename: ENV.ROUTER_BASE_URL }
  )

  if (!window.location.pathname.includes(ENV.ROUTER_BASE_URL)) {
    window.history.replaceState("", "", ENV.ROUTER_BASE_URL + window.location.pathname)
  }

  return <RouterProvider router={router} />
}

export default App
