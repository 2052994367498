const API_BASE_HOSTNAME = process.env.REACT_APP_API_BASE_HOSTNAME || "https://store.rvo.fr"
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://store.rvo.fr/api/store"
const ROUTER_BASE_URL = process.env.ROUTER_BASE_URL || "/store"

const ENV = process.env.REACT_APP_ENV || "PRD"

const DEV_TOKEN = ""

const ACR_VALUES_ARCA = process.env.REACT_APP_ACR_VALUES || "secure/name/x509-kerb-form/uri"
const CLIENT_ID_ARCA = process.env.REACT_APP_CLIENT_ID_ARCA || "c2d1fd2b-2f85-4672-b9c2-4993ab227255"
const REDIRECT_URI = "https://store.rvo.fr/store"
const REQUESTED_SCOPES_ARCA = process.env.REACT_APP_REQUESTED_SCOPES || "role arcaX role-vvo-irn70754"

const OKTA_CONFIG = {
  REDIRECT_URI: "https://store.rvo.fr/store/login",
  LOGOUT_URL: "https://store.rvo.fr/store/logout",
  ISSUER: "https://sso.renault.com/oauth2/aus133y6mks4ptDss417",
  CLIENT_ID: "irn-70754_ope_native_hhp5yvcz9rpv",
  REQUESTED_SCOPES: ["alliance_profile", "openid"],
}

export {
  ENV,
  ROUTER_BASE_URL,
  API_BASE_HOSTNAME,
  API_BASE_URL,
  DEV_TOKEN,
  ACR_VALUES_ARCA,
  CLIENT_ID_ARCA,
  REDIRECT_URI,
  REQUESTED_SCOPES_ARCA,
  OKTA_CONFIG,
}
