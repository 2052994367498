import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

import { LOCAL_STORAGE } from "utils/authentication-constants.utils"

import { AuthState, Credentials } from "./auth.types"

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem(LOCAL_STORAGE.API_TOKEN),
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.isAuthenticated = true
    },
    reset: (state) => {
      state.isAuthenticated = false
    },
    setCredentials: (state, action: PayloadAction<Credentials>) => {
      state.credentials = action.payload
    },
  },
})

export const { login, reset, setCredentials } = authSlice.actions
export default authSlice.reducer
