import React, { useEffect } from "react"

import { toRelativeUrl } from "@okta/okta-auth-js"
import { useOktaAuth } from "@okta/okta-react"
import { useDispatch } from "react-redux"
import { Outlet } from "react-router-dom"

import AbsoluteLoader from "components/loader"
import actions from "store/actions"
import { getCredentials } from "utils/authentication.utils"

const SecureRoute = () => {
  const { oktaAuth, authState } = useOktaAuth()
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      if (!authState) {
        return
      }

      const credentials = getCredentials(authState)

      if (!authState?.isAuthenticated) {
        const originalUri = toRelativeUrl(window.location.href, window.location.origin)
        const originalUriReplace = originalUri.replace(/^\/store/, "")
        oktaAuth.setOriginalUri(originalUriReplace)
        await oktaAuth.signInWithRedirect()
      } else if (credentials) {
        dispatch(actions.auth.setCredentials(credentials))
      }
    })()
  }, [authState?.isAuthenticated, authState?.accessToken])

  if (!authState || !authState?.isAuthenticated) {
    return <AbsoluteLoader />
  }

  return <Outlet />
}

export default SecureRoute
